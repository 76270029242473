import React, { useState, useRef, useEffect } from 'react';
import SideHeader from "../../components/SideHeader";
import Header from "../../components/header/HeaderOne";
import Footer from "../../components/footer/FooterThree";

const Memberships = () => {
    const [programType, setProgramType] = useState(null);
    const [ageGroup, setAgeGroup] = useState(null);
    const [subscriptionType, setSubscriptionType] = useState(null);
    const ageGroupRef = useRef(null); // Ref for age group section
    const subscriptionTypeRef = useRef(null); // Ref for subscription type section

    // Prices based on program and age group
    const prices = {
        Adults: {
            Monthly: '190.00',
            Yearly: '2,090.00'
        },
        Kids: {
            '3-4': {
                Monthly: '139.00',
                Yearly: '1,774.00'
            },
            '5-13': {
                Monthly: '149.00',
                Yearly: '1,774.00'
            },
            'Comp Kids': {
                Monthly: '180.00',
                Yearly: '1,980.00'
            }
        }
    };

    const urlMappings = {
        'Adults  Monthly': "https://cart.mindbodyonline.com/sites/93760/cart/add_contract?mbo_item_id=120",
        'Adults  Yearly': "https://cart.mindbodyonline.com/sites/93760/cart/add_contract?mbo_item_id=142",
        'Kids 5-13 Monthly': "https://cart.mindbodyonline.com/sites/93760/cart/add_contract?mbo_item_id=139",
        'Kids 5-13 Yearly': "https://cart.mindbodyonline.com/sites/93760/cart/add_contract?mbo_item_id=138",
        'Kids 3-4 Yearly': "https://cart.mindbodyonline.com/sites/93760/cart/add_contract?mbo_item_id=138",
        'Kids 3-4 Monthly': "https://cart.mindbodyonline.com/sites/93760/cart/add_contract?mbo_item_id=141",
        'Kids Comp Kids Monthly': "https://cart.mindbodyonline.com/sites/93760/cart/add_contract?mbo_item_id=140",
        'Kids Comp Kids Yearly': "https://cart.mindbodyonline.com/sites/93760/cart/add_contract?mbo_item_id=144",
    };

    const handleProgramTypeSelect = (type) => {
        setProgramType(type);
        setAgeGroup(null);
        setSubscriptionType(null);
    };

    const handleAgeGroupSelect = (group) => {
        setAgeGroup(group);
        setSubscriptionType(null);
    };

    const handleSubscriptionTypeSelect = (type) => {
        setSubscriptionType(type);
    };

    const getDisplayPrice = (type) => {
        if (programType === 'Adults') {
            return prices.Adults[type];
        } else if (programType === 'Kids' && ageGroup) {
            return prices.Kids[ageGroup][type];
        }
    };

    const getMindbodyUrl = () => {
        let key = `${programType} ${ageGroup || ''} ${subscriptionType}`;
        console.log(key)
        return urlMappings[key] || "#";  // Fallback to "#" if no URL is found
    };

    // Function to scroll to the bottom of the page
    const scrollToBottom = () => {
        window.scrollTo({
            top: document.body.scrollHeight,
            behavior: 'smooth'
        });
    };

        // useEffect to trigger scroll when the relevant states are updated
        useEffect(() => {
            if (programType || ageGroup || subscriptionType) {
                scrollToBottom();
            }
        }, [programType, ageGroup, subscriptionType]);

    return (
        <div className={'main-wrapper p-0'}>
            <Header classes={'position-static'}/>
            <SideHeader mobile={true}/>

            <div className="free-trial-container">
                <div className="header">
                    <h1>Purchase Membership</h1>
                    <p>Select the type of membership you want to purchase.</p>
                </div>

                {/* Program Type Selection */}
                <div style={{ marginBottom: "40px" }}>
                    <h4>Program Type</h4>
                    <div className="button-group">
                        <button className={`btn ${programType === 'Adults' ? 'btn-dark' : 'btn-light'}`} onClick={() => handleProgramTypeSelect('Adults')}>Adults</button>
                        <button className={`btn ${programType === 'Kids' ? 'btn-dark' : 'btn-light'}`} onClick={() => handleProgramTypeSelect('Kids')}>Kids</button>
                    </div>
                </div>

                {/* Conditional Age Group Selection for Kids */}
                {programType === 'Kids' && (
                    <div style={{ marginBottom: "40px" }} ref={ageGroupRef}>
                        <h4>Age Group</h4>
                        <div className="button-group">
                            <button className={`btn ${ageGroup === '3-4' ? 'btn-dark' : 'btn-light'}`} onClick={() => handleAgeGroupSelect('3-4')}>3-4</button>
                            <button className={`btn ${ageGroup === '5-13' ? 'btn-dark' : 'btn-light'}`} onClick={() => handleAgeGroupSelect('5-13')}>5-13</button>
                            <button className={`btn ${ageGroup === 'Comp Kids' ? 'btn-dark' : 'btn-light'}`} onClick={() => handleAgeGroupSelect('Comp Kids')}>Comp Kids</button>
                        </div>
                    </div>
                )}

                {/* Subscription Type Selection */}
                {(programType === 'Adults' || (programType === 'Kids' && ageGroup)) && (
                    <div style={{ marginBottom: "40px" }} ref={subscriptionTypeRef}>
                        <h4>Select Membership Plan:</h4>
                        <div className="button-group" style={{ flexDirection: "row", alignItems: "stretch", width: "80%" }}>
                            <button
                                className={`btn px200 ${subscriptionType === 'Monthly' ? 'btn-dark' : 'btn-light'}`}
                                style={{ textAlign: "left", padding: "10px 20px", display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "10px" }}
                                onClick={() => handleSubscriptionTypeSelect('Monthly')}
                            >
                                <span style={{ textAlign: "left" }}>Monthly Unlimited</span>
                                <span style={{ textAlign: "right" }}>${getDisplayPrice('Monthly')} per month</span>
                            </button>
                            <button
                                className={`btn ${subscriptionType === 'Yearly' ? 'btn-dark' : 'btn-light'}`}
                                style={{ textAlign: "left", padding: "10px 20px", display: "flex", justifyContent: "space-between", alignItems: "center" }}
                                onClick={() => handleSubscriptionTypeSelect('Yearly')}
                            >
                                <span style={{ textAlign: "left" }}>Annual Unlimited</span>
                                <span style={{ textAlign: "right" }}>${getDisplayPrice('Yearly')} per year</span>
                            </button>
                        </div>
                    </div>
                )}

                {/* Show Buy Membership Button */}
                {subscriptionType && (
                    <div className="button-group" style={{ marginBottom: "40px", width: "200px", flexDirection: "column"  }}>
                            <button
                                disabled={true}
                                className={`btn ${subscriptionType === 'Yearly' ? 'btn-dark' : 'btn-light'}`}
                                style={{ backgroundColor: "#c4c4c4", textAlign: "left", padding: "10px 20px", display: "flex", justifyContent: "space-around", alignItems: "center" }}
                            >
                                <span style={{ textAlign: "center", fontSize: "9px" }}>Cancel at anytime, with 30 days notice.</span>
                            </button>
                        
                        <a 
                            href={getMindbodyUrl()} 
                            target="_blank" 
                            rel="noopener noreferrer" 
                            className="btn btn-dark"
                        >
                            Buy {programType} Membership
                        </a>
                    </div>
                )}
            </div>

            <Footer position={'static'}/>
        </div>
    );
};

export default Memberships;
